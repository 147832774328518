body, html {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

/* Hide scrollbar for Chrome, Edge, and Safari */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
body {
  scrollbar-width: none; /* Firefox */
}
